const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "时间",
                prop: "CJSJ",
                minWidth: "180"
            }, {
            //     label: "空气质量级别",
            //     prop: "AQI_CLASS",
            //     minWidth: "100"
            // }, {
                label: "CO(ppm)",
                prop: "CO_AVG_DENSITY",
                minWidth: "100"
            }, {
                label: "SO₂(ppm)",
                prop: "SO2_AVG_DENSITY",
                minWidth: "100"
            }, {
                label: "NO₂(ppm)",
                prop: "NO2_AVG_DENSITY",
                minWidth: "100"
            }, {
                label: "PM10(mg/m³)",
                prop: "PM10_AVG_DENSITY",
                minWidth: "120"
            }, {
                label: "PM2.5(μg/m³)",
                prop: "PM25_AVG_DENSITY",
                minWidth: "120"
            }, {
                label: "O₃(mg/L)",
                prop: "O3_AVG_DENSITY",
                minWidth: "100"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
        //     isShow: true,
        //     btnType: 'button',
        //     operateType: 'buttonNew',
        //     name: '新建',
        //     round: true,
        //     backColor: '#28ccd9',
        // }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };